import React, { Component } from "react"
import Layout from "../components/layout"
import BugBountySGN from "../components/BugBountySGN/BugBountySGN"

export default class bugBountySGN extends Component {
  render() {
    return (
      <Layout>
        <BugBountySGN />
      </Layout>
    )
  }
}
