import React from "react"
import Section from "../global/Section"
import "./bugBountySGN.scss"

export default function BugBountySGN() {
  return (
    <Section>
      <div className="bugBountySGN">
        <div className=""></div>
        <div className="justify-content-center">
          <div className="title">
            State Guardian Network Bug Bounty (Preview)
          </div>
          <div className="content">
            Celer Network’s State Guardian Network has not been covered by any
            existing bug bounty before. We are planning to launch a new bug
            bounty program focusing on the State Guardian Network code base
            soon. This page contains the reward information for this future
            bounty program and basic criteria of the bounty.
          </div>
          <div className="content">
            We encourage early and responsible disclosure of vulnerability and
            will carry out good faith payout in either stable tokens or
            equivalent amount of CELR tokens, but please do note that this
            bounty is not officially launched and the scope of each category is
            subject to adjustment. Therefore, we reserve the right to interpret
            the scope and potentially lower the payout amount on a case-by-case
            basis during the pre-launch phase.
          </div>
          <div className="content">
            <div className="content_title">Rewards</div>
            For the rewards, payment can be in mainstream stable tokens or
            equivalent amounts of CELR tokens solely based on the discretion of
            the bounty issuer. The reward payout will be done within 180 days of
            the confirmation of the report.{" "}
          </div>
          <div className="content">
            <table border={1}>
              <tr>
                <td>Severity Level</td>
                <td>Reward</td>
              </tr>
              <tr>
                <td>Critical</td>
                <td>$200K </td>
              </tr>
              <tr>
                <td>High</td>
                <td>$75K </td>
              </tr>
              <tr>
                <td>Medium</td>
                <td>$20K </td>
              </tr>
            </table>
          </div>
          <div className="content">
            <div className="content_title">Severity Levels</div>
            <div className="content_title2">Critical</div>
          </div>
          <div>
            <div className="dot" />
            Without compromising any validator, the attacker can make fake
            events (e.g., token or message bridge events and staking events)
            accepted and signed by the majority of the validators.
          </div>
          <div>
            <div className="dot" />
            Without compromising any validator, the attacker can break the
            integrity of cross-chain token and message bridge, i.e., bridge
            incorrect tokens or messages across chains.
          </div>
          <div>
            <div className="dot" />
            Without compromising any validator, the attacker can cause permanent
            loss of funds for users.
          </div>
          <div>
            <div className="dot" />
            Without compromising any validator, the attacker can permanently
            halt the chain without any possibility of recovery.
          </div>
          <div>
            <div className="dot" />
            Without compromising any validator, make a governance proposal pass
            without votes from the quorum of the validators’s stake.
          </div>
          <div className="content">
            <div className="content_title2">High</div>
            <div>
              <div className="dot" />
              Attacks that would be critical if one or more validators, together
              holding less than ⅓ of the stake, are compromised.
            </div>
            <div>
              <div className="dot" />
              Attacks that would be critical but are very capital-intensive to
              carry out.
            </div>
            <div>
              <div className="dot" />
              Without compromising any validator, halt the chain for an extended
              period of time.
            </div>
          </div>
          <div className="content">
            <div className="content_title2">Medium</div>
            <div>
              <div className="dot" />
              With one validator being compromised, halt the chain for an
              extended period of time.
            </div>
            <div>
              <div className="dot" />
              Cryptographic implementation flaws with limited impact.
            </div>
          </div>

          <div className="content">
            <div className="content_title">Out of Scope and Rules</div>
            <div className="firstPage">
              The following vulnerabilities are excluded from the rewards for
              this bug bounty program:
            </div>
            <div>
              <div className="dot" />
              Attacks with root causes that are not in the SGN codebase.
            </div>
            <div>
              <div className="dot" />
              Attacks that the reporter has already exploited themselves,
              leading to damage
            </div>
            <div>
              <div className="dot" />
              Attacks requiring access to leaked any directly keys/credentials
            </div>
            <div>
              <div className="dot" />
              Attacks requiring access to privileged addresses (governance,
              strategist)
            </div>
          </div>
          <div className="content">
            <div className="firstPage">Smart Contracts and Blockchain</div>
            <div>
              <div className="dot" />
              Incorrect data supplied by third party oracles
            </div>
            <div>
              <div className="dot" />
              Not to exclude oracle manipulation/flash loan attacks
            </div>
            <div>
              <div className="dot" />
              Basic economic governance attacks (e.g. majority stake attack)
            </div>
            <div>
              <div className="dot" />
              Lack of liquidity
            </div>
            <div>
              <div className="dot" />
              Best practice critiques
            </div>
            <div>
              <div className="dot" />
              Sybil attacks
            </div>
          </div>
          <div className="content">
            <div className="firstPage">
              The following activities are prohibited by this bug bounty
              program:
            </div>
            <div>
              <div className="dot" />
              Any testing with mainnet or public testnet contracts; all testing
              should be done on private testnets
            </div>
            <div>
              <div className="dot" />
              Any testing with pricing oracles or third party smart contracts
            </div>
            <div>
              <div className="dot" />
              Attempting phishing or other social engineering attacks against
              our employees and/or customers
            </div>
            <div>
              <div className="dot" />
              Any testing with third party systems and applications (e.g.
              browser extensions) as well as websites (e.g. SSO providers,
              advertising networks)
            </div>
            <div>
              <div className="dot" />
              Any denial of service attacks
            </div>
            <div>
              <div className="dot" />
              Automated testing of services that generates significant amounts
              of traffic
            </div>
            <div>
              <div className="dot" />
              Public disclosure of an unpatched vulnerability in an embargoed
              bounty
            </div>
          </div>
          <div className="content">
            <div className="content_title"> Report Submission</div>
            Please send a plain-text email detailing the issue and steps to
            reproduce to bug@celer.network.
          </div>
        </div>
      </div>
    </Section>
  )
}
